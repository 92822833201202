<template>
    <div class="saidbar" v-if="flag">
      <div class="menu card-header"> <span>Ваши заказы: </span> <div class="loader-menu display-none"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div></div>
      <div class="menu-link">
        <menuLink :arrOrderId="arrOrderId"
                  :GET="GET"
                  @displayBlock="displayBlock"
                  @orderForm="orderForm"
                  @getOrderInfo="getOrderInfo"
                  @getPriceArr="getPriceArr"
                  @sumPrice="sumPrice"
                  @orderIdForm="orderIdForm"
                  @loderMenu="loderMenu"

        ></menuLink>
      </div>
    </div>
    <div v-else class="alert alert-danger" style="margin: 50px">
      <strong>Ошибка!</strong> Ссылка не действительна
    </div>
</template>

<script>
import menuLink from './menuLink';
import  loader from '../loader';

export default {
  name: "menu",
  components:{
    menuLink, loader,
  },
  data() {
    return {
      arrOrderId: [],
      GET: {},
      flag: true
    }
  }
  ,
  methods: {
    displayBlock(flag){
      this.$emit('displayBlock', flag)
      let menu = document.querySelector('.saidbar')
      menu.classList.remove('display-block');
    },
    getOrderInfo(arr){
      // console.log('arrOrdersInfo_1')
      // console.log(arr)
      // console.log('arrOrdersInfo_1')
      this.$emit('getOrderInfo', arr)

    },
    orderForm(obj){
      this.$emit('orderForm', obj)
    },
    getPriceArr(arr){
      this.$emit('getPriceArr', arr)
    },
    sumPrice(sum){
      this.$emit('sumPrice', sum)
    },
    orderIdForm(id){
      this.$emit('orderIdForm', id)
    },
    loderMenu(flag){
      let loader = document.querySelector('.loader-menu')
        // console.log('remove')
        loader.classList.remove('display-none')
    },
    loaderMenu2(){
      let loader = document.querySelector('.loader-menu')
      loader.classList.add('display-none')
      // console.log('add')
    },
    // flagLinks(flag){
    //   this.$emit('flagLinks', flag)
    // },
    getParams(name){
      let params = window
          .location
          .search
          .replace('?','')
          .split('&')
          .reduce(
              function(p,e){
                let a = e.split('=');
                p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                return p;
              },
              {}
          );
      return params[name]
    },
    showLoader(){

    }
  },
  // created() {
  //   this.arrOrderId =[1];
  // },
  created: function (){
    let formData = new FormData();
    this.GET = {
      customerId: this.getParams('customer_id'),
      // hash: this.getParams('hash')
    }

    // HASH ВВЕРХ И ВНИЗ

    formData.append('customer_id', this.GET.customerId);
    // formData.append('hash', this.GET.hash);

    axios.post("https://service.monro-art.ru/vendor/getOrders.php",
        formData
        , {
          headers: {
            "Content-type": "application/json",
          },
        })
        .then((res) => {
          console.log('res')
          console.log(res)
          if(res.data && res.data.status && res.data.orders.length){
            this.arrOrderId = res.data.orders;
            // console.log('if')
            // console.log('this.arrOrderId MENU')
            // console.log(this.arrOrderId)
          }
          else{
            this.flag = false;
            this.$emit('orderFlag', false)
            // console.log('else')
          }

        }).catch(e => console.log(e));
  },
  mounted() {
    this.$emit('GET', this.GET)

    this.$root.$on('loaderMenuComponent', () => {
      this.loaderMenu2();
    })
  }

}
</script>

<style scoped>
  .saidbar {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;

    display: flex;
    flex-direction: column;
    min-width: 130px;


    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    color: #3c3c3c;
  }

  .display-block{
    display: block !important;
  }

  .display-none{
    display: none;
  }


  .menu{
    border-bottom: 1px solid #ccc;
  }
  @media (min-width: 701px){
    .saidbar{
      display: block !important;
    }
  }

  @media (max-width: 701px){
    .saidbar{
      margin-left: 10px;
    }
  }

  @media(max-width: 600px){
    .nodisplay{
      display: none;
    }
    .saidbar{
      position: relative;
      transition: all .5s linear;
      width: 80vh;
      margin-right: 10px;
    }
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    /*width: 30px;*/
    /*height: 30px;*/
    /*top: 30%;*/
    bottom: 20%;
    margin-left: 10px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 0;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #a0a0a0;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 2px;
    animation: lds-ellipsis1 0.8s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 2px;
    animation: lds-ellipsis2 0.8s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 8px;
    animation: lds-ellipsis2 0.8s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 14px;
    animation: lds-ellipsis3 0.8s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(6px, 0);
    }
  }
</style>