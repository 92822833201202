<template>
  <div id="app">
    <header-logo></header-logo>
    <div>
      <router-view/>
    </div>
    <footer-content></footer-content>
  </div>
</template>

<script>



// import menu from './components/menu';
// import content from "./components/content";
import container from "./components/mainComponent";
import headerLogo from './components/headerComponent';
import footerContent from './components/footerComponent';

export default {
  name: 'App',
  components: {
    'container-main' : container,
    'header-logo' : headerLogo,
    'footer-content' : footerContent,
  },
  mounted() {
  }
}
</script>

<style>
#app {
  display: flex;
  margin: 50px auto;
  flex-direction: column;
  justify-content: center;

}

.main{
  display: flex;
  /*margin: 50px auto;*/
  flex-direction: row;
  justify-content: center;
}
</style>
