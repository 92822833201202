<template>
  <div>
    <loader v-if="!flag"></loader>
    <div class="main">
      <menuComponent
                     v-show="flag"
                     class="slidebar-menu"
                     :class="style"
                     @displayBlock="displayBlock"
                     @getOrderInfo="getOrderInfo"
                     @orderForm="orderForm"
                     @getPriceArr="getPriceArr"
                     @sumPrice="sumPrice"
                     @orderIdForm="orderIdForm"
                     @GET="GET"
                     @orderFlag="orderFlag"
      ></menuComponent>
      <contentComponent
          v-show="flag"
          class="content-menu"
          :class="noStyle"
          :arrOrdersInfo="arrOrdersInfo"
          :productId="productId"
          :priceArr="priceArr"
          :sumPrice="sum"
          :orderId="orderId"
          :GET="get"
          @showMenu="showMenu"
      ></contentComponent>
    </div>
  </div>

</template>

<script>
import menu from './sidebar/menu';
import content from "./content/content";
import loader from "./loader";


export default {
  name: "mainComponent",
  components: {
    'menuComponent' : menu,
    'contentComponent' : content,
    loader,
  },
  data(){
    return{
      arrOrdersInfo: [],
      productId: {},
      priceArr: [],
      sum: 0,
      orderId: 0,
      get: {},
      flag: false,
      // displayMenu: false,
      style: 'display-block',
      noStyle: 'display-none',
    }
  },
  methods: {
    getOrderInfo(arr){
      this.arrOrdersInfo = arr;
    },
    orderForm(data){
      this.productId = data;
      // console.log('data')
      // console.log(data)
      // console.log('qwe')
      // console.log(this.arrProduct);
    },
    getPriceArr(arr){
      this.priceArr = arr;
    },
    sumPrice(sum){
      this.sum = sum
    },
    orderIdForm(id){
      this.orderId = id;
    },
    GET(get){
      this.get = get;
    },
    showMenu(flag){
      let menu = document.querySelector('.slidebar-menu')
      let content = document.querySelector('.content-menu')
      menu.classList.remove('display-none')
      menu.classList.add('display-block')
      content.classList.remove('display-block')
      content.classList.add('display-none')
    },
    displayBlock(flag){
      this.flag = flag
      let menu = document.querySelector('.slidebar-menu')
      let content = document.querySelector('.content-menu')
      content.classList.remove('display-none')
      content.classList.add('display-block')
      menu.classList.remove('display-block')
      menu.classList.add('display-none')
    },
    orderFlag(flag){
      if(!flag){
        setTimeout(function (){
              // console.log('123');
              let loader = document.querySelector('.loader')
              if(loader){
                loader.style.display = 'none';
              }
            }, 1000)
      }

    }
  },
  created() {
    // let formData = new FormData();
    // formData.append('ok', 'ok');
    // axios.post("https://service.monro-art.ru/vendor/returnOk.php",
    //     formData
    //     , {
    //       headers: {
    //         "Content-type": "application/json",
    //       },
    //     })
    //     .then((res) => {
    //       console.log('res')
    //       console.log(res)
    //     }).catch(e => console.log('eee' + e));
  },
  updated() {
    // console.log(this.flag);
    // if(!this.flag){
    //   setTimeout(function (){
    //         console.log('123');
    //         let loader = document.querySelector('.loader')
    //     if(loader){
    //       loader.style.display = 'none';
    //     }
    //   },
    //       2000)
    // }

  }
}
</script>

<style scoped>
.main{
  display: flex;
  /*margin: 50px auto;*/
  flex-direction: row;
  justify-content: center;
}
.display-block, .display-none{
  display: block;
}
menuComponent, contentComponent{
  transition: all .5s linear;
}

@media(max-width: 600px) {

  .display-block{
    display: block;
  }
  .display-none{
    display: none;
  }
}



</style>