<template >
  <main>
    <div class="main">


      <div class="form-group card fanks" v-show="showThanks" style="margin: 0 auto">
        <label class="card-header radioBtnContainerRow">
          <h5>Спасибо за Ваш отзыв! Вы делаете нас лучше</h5>
        </label>
      </div>

      <form
          action="https://service.monro-art.ru/vendor/sendReview.php"
          method="post"
          name="review"
      >
        <div class="main-header">
<!--          !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!HASH!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
<!--          :to="'/?hash=' + hash + '&customer_id=' + clientId "-->
          <router-link
              :to="'/?customer_id=' + clientId"
              class="link-form btn btn-light"
              style="margin: 20px 0 10px 20px;"
          >Назад
          </router-link>
        </div>
        <input type="hidden" name="order_id" v-model="orderId" />
        <div id="reviewBlock" v-show="showReview" style="margin: 0 20px;">
          <div class="form-group card">
            <label class="card-header"
            >Понравилась ли Вам картина? Оцените от 1 до 5
              <span class="colorStar">*</span></label
            >
            <div class="radioBtnContainerRow p-2">
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="pictureRating"
                    v-model="formBody.pictureRating"
                    id="pictureRating1"
                    value="1"
                />
                <label class="form-check-label" for="pictureRating1"
                >1</label
                >
              </div>
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="pictureRating"
                    v-model="formBody.pictureRating"
                    id="pictureRating2"
                    value="2"
                />
                <label class="form-check-label" for="pictureRating2"
                >2</label
                >
              </div>
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="pictureRating"
                    v-model="formBody.pictureRating"
                    id="pictureRating3"
                    value="3"
                />
                <label class="form-check-label" for="pictureRating3"
                >3</label
                >
              </div>
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="pictureRating"
                    v-model="formBody.pictureRating"
                    id="pictureRating4"
                    value="4"
                />
                <label class="form-check-label" for="pictureRating4"
                >4</label
                >
              </div>
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="pictureRating"
                    v-model="formBody.pictureRating"
                    id="pictureRating5"
                    value="5"
                />
                <label class="form-check-label" for="pictureRating5"
                >5</label
                >
              </div>
            </div>
          </div>
          <div class="form-group card">
            <label class="card-header"
            >Остались ли Вы довольны общением с нашим менеджером?
              Оцените от 1 до 5
              <span class="colorStar">*</span></label
            >
            <div class="radioBtnContainerRow p-2">
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="managerRating"
                    v-model="formBody.managerRating"
                    id="managerRating1"
                    value="1"
                />
                <label class="form-check-label" for="managerRating1"
                >1</label
                >
              </div>
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="managerRating"
                    v-model="formBody.managerRating"
                    id="managerRating2"
                    value="2"
                />
                <label class="form-check-label" for="managerRating2"
                >2</label
                >
              </div>
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="managerRating"
                    v-model="formBody.managerRating"
                    id="managerRating3"
                    value="3"
                />
                <label class="form-check-label" for="managerRating3"
                >3</label
                >
              </div>
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="managerRating"
                    v-model="formBody.managerRating"
                    id="managerRating4"
                    value="4"
                />
                <label class="form-check-label" for="managerRating4"
                >4</label
                >
              </div>
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="managerRating"
                    v-model="formBody.managerRating"
                    id="managerRating5"
                    value="5"
                />
                <label class="form-check-label" for="managerRating5"
                >5</label
                >
              </div>
            </div>
          </div>
          <div class="form-group card">
            <label class="card-header"
            >Доставка была в срок?<span class="colorStar"
            >*</span
            ></label
            >
            <div class="radioBtnContainerColumn p-2">
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="deliveredOnTime"
                    v-model="formBody.deliveredOnTime"
                    id="deliveredOnTime1"
                    value="1"
                />
                <label
                    class="form-check-label"
                    for="deliveredOnTime1"
                >Да</label
                >
              </div>
              <div class="form-check">
                <input
                    class="form-check-input cross"
                    type="radio"
                    name="deliveredOnTime"
                    v-model="formBody.deliveredOnTime"
                    id="deliveredOnTime2"
                    value="0"
                />
                <label
                    class="form-check-label"
                    for="deliveredOnTime2"
                >Нет</label
                >
              </div>
            </div>
          </div>
          <div class="form-group card">
            <label for="customerComment" class="card-header"
            >Если у Вас есть пожелания, то, пожалуйста, оставьте их
              тут:</label
            >
            <textarea
                class="form-control border-0"
                name="wishes"
                v-model="formBody.wishes"
                id="customerComment"
                rows="3"
                placeholder="Мой ответ"
            ></textarea>
          </div>
        </div>

        <div
            class="form-group card"
            id="tipBlock"
            v-show="showTips"
            style="margin-left: 20px; margin-right: 20px;"
        >
          <label class="card-header"
          >Спасибо, что Вы выбрали нас и доверили самое важное-Ваши
            воспоминания. Благодаря Вам, мы становимся лучше с каждым
            днем. Если наши чувства взаимны, Вы можете оставить чаевые
            для нашей команды художников и менеджеров. Для этого
            пройдите, пожалуйста, по ссылке. Но решение всегда за Вами
          </label>
          <div class="radioBtnContainerRow p-2">
            <input
                type="text"
                class="form-control border-1"
                name="sum"
                id="defaultPrice"
                placeholder="Введите сумму"
                v-model="formBody.sum"
            />
          </div>
          <div class="p-2 buttonContainerRow row">
            <div class="form-group col-md-3">
              <button
                  class="btn btn-dark"
                  type="button"
                  @click="setPrice(100)"
                  style="width: 100%"
              >
                100
              </button>
            </div>
            <div class="form-group col-md-3">
              <button
                  class="btn btn-dark"
                  type="button"
                  @click="setPrice(300)"
                  style="width: 100%"
              >
                300
              </button>
            </div>
            <div class="form-group col-md-3">
              <button
                  class="btn btn-dark"
                  type="button"
                  @click="setPrice(1000)"
                  style="width: 100%"
              >
                1000
              </button>
            </div>
          </div>
        </div>

        <!--      ОТПРАВКА-->

        <button
            type="button"
            class="btn btn-dark pay-btn"
            id="nextButton"
            @click="leaveTip"
            v-show="showReview"
        >
          Далее
        </button>
        <button
            type="submit"
            class="btn btn-dark pay-btn"
            id="submitButton"
            v-show="showTips"
        >
          Отправить
        </button>
        <button
            type="button"
            v-show="showTips"
            class="btn btn-gray pay-btn"
            id="submitButton2"
            style="
                      opacity: 1;
                      color: black;
                      background-color: rgb(239, 239, 239);
                  "
            @click="setPrice(0)"
        >
          Отказаться от чаевых
        </button>
      </form>

    <!--    <?php endif;?>-->
    </div>
  </main>
</template>

<script>
export default {
  name: "form2",
  props: ["orderId", "clientId", "hash"],
  data() {
    return {
      showThanks: false,
      showReview: true,
      showTips: false,

      formBody: {
        order_id: this.orderId,
        pictureRating: "",
        managerRating: "",
        deliveredOnTime: "",
        wishes: "",
        sum: 300,
      },
    };
  },
  methods: {
    leaveTip() {
      this.showReview = false;
      this.showTips = true;
    },
    setPrice(price) {
      this.formBody.sum = price;
      if (price == 0) this.sendWithoutTips();
    },
    sendWithoutTips() {
      let form = document.forms.review;
      let data = new FormData(form);
      data.set('sum', 0);
      axios
          .post(
              "https://service.monro-art.ru/vendor/sendReview.php",
              data,
              {
                headers: {
                  "Content-type": "application/json",
                },
              }
          )
          .then((res) => {
            // console.log(this.formBody);
            // console.log(res);
            this.showReview = false;
            this.showTips = false;
            this.showThanks = true;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>

<style scoped>

main{
  margin: 0 auto;
}

.main {
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
}
.main-header{
  width: 100%;
  margin: 0 auto;
}


form {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.form-group{
  margin-bottom: 10px;
}

.btn {
  margin-right: 0;
}

.pay-btn {
  width: 85%;
  margin-left: 0px;
  margin-top: 20px;
  background-color: #343a40;
  transition: background-color .2s linear;
}
.pay-btn:hover{
  background-color: #282a31;
}

.pay-btn:active{
  border: none;
}

.credentials p {
  margin: 0;
}

.radioBtnContainerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.radioBtnContainerColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.buttonContainerRow {
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
}
.colorStar {
  color: red;
}
.form-check {
  cursor: pointer;
}
.cross {
  cursor: pointer;
}
.btn-dark {
  background-color: #343a40;
}

@media (max-width: 550px){
  .fanks{
    margin: 0 20px;
  }
}
</style>