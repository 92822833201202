<template>
  <div class="footer">
    <footer class="footer container" >
      <div class="row">
        <div class="col-md-12">
          <div class="credentials">
            <p>ООО "Сервис Монро Арт"</p>
            <p>ИНН/КПП 1655461269/165501001</p>
            <p>Юридический и фактический адрес: 420021, Республика Татарстан, г. Казань, ул. Габдуллы Тукая, д. 113А, помещ. 3</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" style="text-align: center;">
<!--          <img style="height: 88px" src="https://cdn.easy-mo.ru/images/sk_green_large.jpg" />-->
          <img style="height: 88px" src="../img/sk_green_large.jpg" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "footerComponent"
}
</script>

<style scoped>
  .footer{
    width: 100%;
    margin-top: 100px;
    padding-bottom: 20px;
  }
  .credentials{
    max-width: 700px;
    font-weight: 200;
    font-size: 0.7rem;
    color: #868585;
    margin: 20px auto;
  }
  .container{
    margin: 0 auto;
  }

  p{
    margin-bottom: 0;
  }
  @media (max-width: 701px){
    .footer{
      margin-top: 0;
    }
  }
</style>