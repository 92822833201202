<template>
<main>
  <div class="alert alert-danger" style="margin: 50px">
    <strong>Ошибка!</strong> Ссылка не действительна
  </div>
</main>
</template>

<script>
export default {
  name: "errorComponent"
}
</script>

<style scoped>

main{
  width: 100%;
  margin: 0 auto;
  text-align: center;
}


</style>