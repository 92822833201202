<template>

  <div style="top: 10px">
    <div class="header-file" >
      Добавить несколько:
      <button class="btn btn-light" @click="addInput"> + 1</button>
    </div>
    <div class="main-file" v-for="(item, index) in arr">
      <div class="input-url">
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">URL:</span>
          <input type="text" class="form-control" placeholder="ссылка" aria-label="Username" aria-describedby="basic-addon1"
          @input="clickInput(index)">
        </div>
        <button class="btn btn-light btn-clear" @click="removeInput(index)">&#10006;</button>
      </div>
    </div>
    <button class="btn btn-light btn-my" @click="submitImg">Загрузить</button>

  </div>
</template>

<script>
export default {
  name: "addImg",
  data(){
    return {
      arr: [1]
    }
  },
  props: {
    product: {
      type: Object,
    }
  },
  methods: {
    addInput(){
      let elem = this.arr.length + 1
      this.arr.push(elem);
    },
    submitImg(){
      let flag = true
      let formData = new FormData();
      let input = document.querySelectorAll('input');
      let product_id = String(this.product.id);
      // console.log(input)
      // console.log('input')
      // console.log(this.product)
      for(let i = 0; i< input.length; i++) {
        if (this.checkUrl(input[i].value)) {
          formData.append('product_id', product_id);
          formData.append('photo_url', input[i].value);
          axios.post("https://service.monro-art.ru/vendor/addPhoto.php",
              formData
              , {
                headers: {
                  "Content-type": "application/json",
                },
              })
              .then((res) => {
                input[i].value = ''
                // console.log('add')
                // console.log(res)
                this.$root.$emit('menuLinkVue')
                // console.log('addend')
              }).catch(e => {
                console.log(e)
                flag = false;
              });
        } else{
          // console.log('err')
          input[i].placeholder = 'Нужна ссылка'
          input[i].classList.add('border-red')
          input[i].value = '';
        }

      }
      if(flag) this.arr = [1];
    },
    removeInput(index){
      this.arr.splice(index, 1);
    },
    checkUrl(name){
        let regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        return regexp.test(name);
    },
    clickInput(i){
      // console.log('ADDIMG')
      let input = document.querySelectorAll('input');
      input[i].classList.remove('border-red')
    }
  }
}
</script>

<style>
  .btn:hover{
    border: 1px solid #bfbfbf;
    box-shadow: none;
  }

  .header-file{
    margin-bottom: 10px;
  }

  .input-url{
    display: flex;
    margin-bottom: 10px;
  }

  .input-group{
    width: 85%;
    margin-right: 10px;
  }
  /*.form-control:focus, .form-control:hover{*/
  /*  border-color: #ced4da;*/
  /*}*/

  .btn-clear{
    /*width: 6%;*/
    height: 10%;
  }
  .btn-clear:hover{
    border: 1px solid #e86868;
  }

  .border-red{
    border-color: red;
  }
</style>

