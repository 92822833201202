<template>
  <div>
    <div class="slideshow-picter" v-if="arr.length">
      <div class="slideshow-container">
        <div class="mySlides fade" v-for="(elem,index) in item.img">
          <div class="numbertext">{{index}} / {{item.img.length}}</div>
          <img
              v-if="elem.url"
              :src="elem.url"
              style="max-width:50%; margin: 0 auto; max-height: 350px">
        </div>
        <a class="prev" @click="plusSlides(-1)">&#10094;</a>
        <a class="next" @click="plusSlides(1)">&#10095;</a>
      </div>
      <br>
      <div style="text-align:center">
        <span v-for="(elem,index) in item.img"
              class="dot"
              @click="currentSlide(index + 1)"
        ></span>
      </div>
    </div>
    <div v-else class="slideshow-container">
      Нет фото
    </div>
    <div>
      <addImg :product="item"
      ></addImg>
    </div>
  </div>

</template>

<script>
import addImg from './addImg';

export default {

  name: "slider",
  components: {
    addImg
  },
  props: {
    index:{
      type: Number,
    },
    item:{
      type: Object
    },
    length: {
      type: Number,
    }
  },
  data(){
    return{
      slideIndex : 1,
      arr : this.item.img,
    }
  },
  methods: {
    plusSlides(n) {
      this.showSlides(this.slideIndex += n, this.index);
    },

    currentSlide(n) {
      this.showSlides(this.slideIndex = n, this.index);
    },

    showSlides(n, index) {
      // console.log('this.item')
      // console.log(this.item)

      let i;
      let parentSlides = document.getElementsByClassName("slideshow-container");
      let slides = parentSlides[index].getElementsByClassName("mySlides");
      let dots = parentSlides[index].parentNode.getElementsByClassName("dot");

      if (n > slides.length) {this.slideIndex = 1}
      if (n < 1) {this.slideIndex = slides.length}

      // console.log('slides.length')
      // console.log(slides.length)

      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
        slides[i].style.opacity = "0";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active-slide", "");
      }

      slides[this.slideIndex-1].style.display = "block";
      slides[this.slideIndex-1].style.opacity = "1";
      dots[this.slideIndex-1].className += " active-slide";
    },
    firstDownload(){
      ///////////////// цикл взять из массива заказов
      ////////////!!!!!!!!!!!!!!!!!!!!///////////////////////
      // console.log('this.length')
      // console.log(this.length)
      for(let i = 0; i< this.length; i++){
        this.showSlides(this.slideIndex, i)
      }


    }
  },
  mounted: function (){
    // console.log('начало')
    this.firstDownload();
    // console.log('прошло')
  },
  // beforeUpdate(){
  //   console.log('начало1')
  //   this.firstDownload();
  //   console.log('прошло1')
  // },
  updated(){
    // console.log('начало2')
    this.firstDownload();
    // console.log('прошло2')
  },
}
</script>

<style>


.slideshow-picter{
  text-align: center;
  /*max-height: 500px;*/
}
.slideshow-container {
  max-width: 100%;
  position: relative;
  margin: auto;
}
/* Кнопки next & previous*/
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 100px;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #525252;
  font-weight: bold;
  font-size: 30px;
  transition: 0.2s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  text-decoration: none;

}
/* Измените положение "next button" */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
.prev{
  left: 0;
  border-radius: 3px 0 0 3px;
}
/* Добавьте черный фоновый цвет с небольшой прозрачностью*/
.prev:hover, .next:hover {
  color: #8c8c8c;
  text-decoration: underline;
}
/* Подпись под изображением */
.text {
  background-color: #000;
  color: #10a700;
  font-size: 30px;
  font-weight: 700;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  text-align: center;
}
/* Number text (1/3 etc) */
.numbertext {
  color: #ffffff;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
/* Точки/маркеры/указатели */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #999999;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active-slide, .dot:hover {
  background-color: #111111;
}
/* Выцветание анимации */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

.slide-display{
  display: block;
  opacity: 1;
}

.slide-none{
  display: none;
  opacity: 0;
}

@media (max-width: 600px){
  .prev, .next{
    top: 50px;
  }
}
</style>