import Vue from 'vue'
import App from './App'
import router from './router'

Vue.config.productionTip = false

window.axios = require('axios').default;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// header('Access-Control-Allow-Credentials', true)
// header('Access-Control-Allow-Headers', 'X-Requested-With,Content-Type,X-Token-Auth,Authorization')

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
